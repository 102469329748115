

















































































import { Source } from "@/models";
import ConfirmModal from "@/components/ConfirmModal.vue";
import Search from "@/components/VEntrySearch.vue";
import EmptyView from "@/components/EmptyView.vue";
import VImg from '@/components/VImage.vue';
import {
  Vue,
  Component,
  Mixins,
  Watch,
  Ref
} from "vue-property-decorator";
import { namespace } from "vuex-class";
import CutFilesMixin from "@/mixins/http/CutFilesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import VColor from "@/components/VColor.vue";
import { CutFile } from "@/models";
import EditModal from "@/components/CutFile/Edit.vue";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

const getTag = "get_labels";
const destroyTag = "destroy_label";

@Component({
  components: {
    VColor,
    EditModal,
    VImg,
    Search,
    VPageContentSticky,
    DataTable,
    ConfirmModal,
    EmptyView,
    RoundThumb
  }
})
export default class CutFileList extends Mixins(CutFilesMixin) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @Ref() editModal: EditModal;
  @Ref() confirmModal: HTMLDivElement;

  items: CutFile[] = [];
  page = 1;

  columns = [
    { name: "image", label: "Immagine" },
    { name: "name", label: "Nome" },
    { name: "actions", label: "" }
  ];

  search: string | null = null;

  created() {
    this.subscribe();
  }

  onCreated(item: CutFile) {
    this.items.unshift(item);
  }

  onUpdated(item: CutFile) {
    const index = this.items.findIndex(o => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const items = await this.getCutFiles(this.payload, getTag);
      return items;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: {items: CutFile[]}) {
    this.page = 1;
    this.items = items.items;
  }

  onDeleted(id: string) {
    this.items = this.items.filter(o => id !== o.id);
  }

  openCreateModal() {
    (this.editModal as any).create(sourceStore.currentSource.id);
  }

  openEditModal(item: CutFile) {
    (this.editModal as any).edit(item);
  }

  async openConfirmModal(item: CutFile) {
    const [data, errors] = await easync(
      (this.confirmModal as any).confirm("Sei sicuro di eliminare questa etichetta?")
    );

    if (errors) {
      showErrorToast("Si è verificato un errore");
      return;
    }

    if (data) {
      this.destroyItem(item.id);
    }
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  async destroyItem(id: string) {
    const [data, errors] = await easync(this.destroyCutFile(id, destroyTag));
    if (errors) {
      showErrorToast("Si è verificato un errore in cancellazione");
      return;
    }

    this.onDeleted(id);
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {

    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      include: ["name"],
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id,
        term: this.search
      }
    };

    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(this.getCutFiles(this.payload, getTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
