

































































import { Component, Prop, Vue, Mixins, Ref } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import CutFilesMixin from "@/mixins/http/CutFilesMixin";
import { NoCache, RegisterHttp } from "@/utils/Decorators";
import CutFile, { CutFilePayload } from "@/models/CutFile";
import { RequestData } from "@/store/types/RequestStore";
import { showToast } from "@/utils/Toast";
import FileUploader from "@/components/FileUploader.vue";

const updateTag = "update_user";
const createTag = "create_user";

@Component({
  components: {
    VModal,
    FileUploader
  }
})
export default class EditCutFileModal extends Mixins(CutFilesMixin) {
  item: CutFilePayload = null;
  @Ref() readonly modal!: VModal;
  content = "";
  isNew = false;
  file: any = null;

  @RegisterHttp(createTag) createRequest?: RequestData;
  @RegisterHttp(updateTag) updateRequest?: RequestData;
  @Ref() image: HTMLElement;

  canceled!: any | null;

  get valid() {
    if (!this.item) {
      return false;
    }
    return this.item.name && (this.file || this.item.url);
  }

  title() {
    return `${this.isNew ? "Aggiungi" : "Modifica"} etichetta`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  get url() {
    return this.file?.url || this.item.url;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.valid) {
      this.$emit("confirmed", this.item);
      this.modal.hide();
    }
  }

  protected onHidden() {
    this.reset();
    if (this.canceled) {
      this.canceled();
    }
  }

  edit(item: CutFile) {
    this.isNew = false;
    this.modal.show();
    this.item = Object.assign({}, item);
  }

  reset() {
    this.item = null;
    this.file = null;
    this.file = null;
  }

  create(sourceId: string) {
    this.isNew = true;
    this.modal.show();
    this.item = new CutFilePayload();
    this.item.sourceId = sourceId;
  }

  get loading() {
    return this.createRequest?.loading || this.updateRequest?.loading;
  }

  async save() {
    const data = new FormData();
    data.append("name", this.item.name);
    data.append("sourceId", this.item.sourceId);
    if (this.file) {
      data.append("file", this.file.file);
    }
    try {
      const resp = this.isNew
        ? await this.createCutFile(data, createTag)
        : await this.updateCutFile(this.item.id, data, updateTag);
      const emitTag = this.isNew ? "created" : "updated";
      this.$emit(emitTag, resp);
      this.modal.hide();
      showToast("Etichetta Salvata!");
    } catch (err) {
      showToast("Salvataggio fallito!", {
        type: "error"
      });
    }
  }
}
